<!-- eslint-disable -->
<template>
  <div>
    <div class="d-flex justify-content-center" style="max-height: 300px">
      <canvas :id="id"></canvas>
    </div>
    <b-table
      :items="tableIces"
      :fields="fields"
      show-empty
      class="table table-vertical-center border rounded"
      responsive
    >
      <template #empty="scope">
        <h6 class="text-center">Tidak ada data ditemukan</h6>
      </template>
      <template #emptyfiltered="scope">
        <h6 class="text-center">Tidak ada data ditemukan</h6>
      </template>

      <template #cell(integrity)="row">
        {{ parseToDec(row.item.integrity) }} %
      </template>

      <template #cell(customer_satisfaction)="row">
        {{ parseToDec(row.item.customer_satisfaction) }} %
      </template>

      <template #cell(excellance)="row">
        {{ parseToDec(row.item.excellance) }} %
      </template>

      <template #cell(spirituality)="row">
        {{ parseToDec(row.item.spirituality) }} %
      </template>
    </b-table>
  </div>
</template>
<script src="https://cdn.jsdelivr.net/npm/chart.js"></script>
<script>
export default {
  name: "BarChart",
  props: ["id", "data"],
  data() {
    return {
      chartData: {
        labels: [
          "Babol",
          "Cabanatuan",
          "Daegu",
          "Jerusalem",
          "Fairfield",
          "New York",
          "Gangtok",
          "Buenos Aires",
          "Hafar Al-Batin",
          "Idlib",
        ],
        datasets: [
          {
            label: "Line Chart",
            data: [600, 1150, 342, 6050, 2522, 3241, 1259, 157, 1545, 9841],
            fill: false,
            borderColor: "#2554FF",
            backgroundColor: "#2554FF",
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: false,
              },
              gridLines: {
                display: false,
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
        },
        legend: {
          display: true,
        },
        responsive: true,
        maintainAspectRatio: false,
      },
      chart: null,
      fields: [
        {
          key: "integrity",
          label: "Integrity",
          sortable: false,
        },
        {
          key: "customer_satisfaction",
          label: "Customer Satisfaction",
          sortable: false,
          class: "text-center",
        },
        {
          key: "excellance",
          label: "Excellence",
          sortable: false,
          class: "text-center",
        },
        {
          key: "spirituality",
          label: "Spirituality",
          sortable: false,
          class: "text-center",
        },
      ],
      tableIces: [],
    };
  },
  mounted() {
    this.chart = new Chart(document.getElementById(this.id), {
      type: "radar",
      data: {
        labels: [
          "Integrity",
          "Customer Satisfaction",
          "Excellence",
          "Sprituality",
        ],
        datasets: [
          {
            label: "Indeks",
            data: [0, 0, 0, 0],
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(255, 159, 64, 0.2)",
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(255, 159, 64, 1)",
            ],
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          r: {
            angleLines: {
              display: false,
            },
            suggestedMin: 0,
            suggestedMax: 100,
          },
        },
      },
    });
  },
  watch: {
    data(newVal) {
      if (newVal.length > 0) {
        this.tableIces = [];
        this.tableIces.push({
          integrity: newVal[0],
          customer_satisfaction: newVal[1],
          excellance: newVal[2],
          spirituality: newVal[3],
        });
      }

      this.chart.data.datasets[0].data = newVal;
      this.chart.update();
    },
  },
  methods: {
    getData() {},
    parseToDec(value) {
      return parseFloat(value).toFixed(2);
    },
  },
};
</script>
