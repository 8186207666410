<template>
  <div class="card card-custom p-5">
    <!-- <div class="rounded my-2">
      <l-map
        class="rounded"
        style="height: 300px"
        :zoom="zoom"
        :center="center"
      >
        <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
        <l-marker :lat-lng="markerLatLng"></l-marker>
      </l-map>
    </div> -->
    <b-row>
      <!-- <b-col>
        <b-container
          class="bg-secondary d-flex align-items-center justify-content-center rounded-md rounded"
          style="height: 100%"
        >
          <div>informasi lainnya</div>
        </b-container>
      </b-col> -->
      <b-col>
        <RadarChart :data="chart" ref="radar" id="NationalRecapRadar" />
      </b-col>
    </b-row>

    <div class="mt-5">
      <b-table
        :items="data"
        :fields="fields"
        :busy="isLoading"
        show-empty
        class="table table-head-custom table-vertical-center table-head-bg table-borderless card-table"
        responsive
        :current-page="currentPage"
        :per-page="perPage"
      >
        <template #empty="scope">
          <h6 class="text-center">Tidak ada pegawai ditemukan</h6>
        </template>
        <template #emptyfiltered="scope">
          <h6 class="text-center">Tidak ada pegawai ditemukan</h6>
        </template>
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>

        <template #cell(index)="row">
          {{ perPage * (currentPage - 1) + (row.index + 1) }}
        </template>

        <template #cell(nama_pegawai)="row">
          {{ row.item.student_id.full_name }}
        </template>

        <template #cell(integrity)="row">
          {{
            isAlreadyDone(row.item) && row.item.ices && row.item.ices.length > 0
              ? row.item.ices[0].integrity
                ? row.item.ices[0].integrity + "%"
                : "0" + "%"
              : "N/A"
          }}
        </template>

        <template #cell(customer_satisfaction)="row">
          {{
            isAlreadyDone(row.item)
              ? `${row.item.customer_satisfaction} %`
              : "N/A"
          }}
        </template>

        <template #cell(excellence)="row">
          {{
            isAlreadyDone(row.item) && row.item.ices && row.item.ices.length > 0
              ? row.item.ices[0].excellence
                ? row.item.ices[0].excellence + "%"
                : "0" + "%"
              : "N/A"
          }}
        </template>
        <template #cell(spirituality)="row">
          {{
            isAlreadyDone(row.item) && row.item.ices && row.item.ices.length > 0
              ? row.item.ices[0].spirituality
                ? row.item.ices[0].spirituality + " %"
                : "0" + "%"
              : "N/A"
          }}
        </template>

        <template #cell(action)="data">
          <router-link
            :to="{
              name: 'review-exam',
              params: {
                examId: data.item.exam_id._id,
                employeeId: data.item.student_id._id,
              },
            }"
            href="#"
            class="custom-v-dropdown btn btn-light btn-hover-light-primary btn-icon"
          >
            <i class="fa fa-eye"></i>
          </router-link>
          &nbsp;
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="data.length"
        :per-page="perPage"
        align="right"
        class="my-0"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import L from "leaflet";
import { LMap, LTileLayer, LMarker } from "vue2-leaflet";
import RadarChart from "../chart/RadarChart.vue";

import {
  GET_ALL_PEGAWAI_ASSESSMENT,
  GET_ASSESSMENT_CHART,
} from "@/core/services/store/ujian.module";

export default {
  name: "NationalRecap",
  components: {
    // LMap,
    // LTileLayer,
    // LMarker,
    RadarChart,
  },
  data() {
    return {
      isLoading: false,
      isLoadingChart: false,
      data: [],
      chart: [],
      jabatan: null,
      jabatanOptions: [
        {
          text: "Pilih Jabatan",
          selected: true,
          value: null,
          disabled: true,
        },
      ],
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 5,
      center: [-4.663577, 116.536213],
      markerLatLng: [-4.663577, 116.536213],
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      pageOptions: [10, 20, 50, { value: 100, text: "More" }],
      filter: null,
      fields: [
        {
          key: "index",
          label: "No",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "nama_pegawai",
          label: "Nama Pegawai",
          sortable: true,
        },
        {
          key: "integrity",
          label: "Integrity",
          sortable: true,
          class: "text-center",
        },
        {
          key: "customer_satisfaction",
          label: "Customer Satisfaction",
          sortable: true,
          class: "text-center",
        },
        {
          key: "excellence",
          label: "Excellence",
          sortable: true,
          class: "text-center",
        },
        {
          key: "spirituality",
          label: "Spirituality",
          sortable: true,
          class: "text-center",
        },
        {
          key: "action",
          label: "Aksi",
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    // this.getData();
  },
  methods: {
    lazyMounted() {
      // this.$refs.radar
    },
    getData(queryParams = {}) {
      this.isLoading = true;
      const params = new URLSearchParams();
      if (queryParams.level) {
        params.append("access", queryParams.level);
      }

      this.$store
        .dispatch(GET_ALL_PEGAWAI_ASSESSMENT, {
          id: this.$route.params.id,
          params: params,
        })
        .then((data) => {
          this.isLoading = false;
          this.data = data.data;

          this.getChart(queryParams);
        })
        .catch((response) => {
          this.isLoading = false;
          this.$bvToast.toast(response.data.message, {
            title: `Gagal Memuat Ujian`,
            variant: "danger",
            solid: true,
          });
        });
    },
    getChart(queryParams = {}) {
      this.isLoadingChart = true;
      const params = new URLSearchParams();
      if (queryParams.level) {
        params.append("access", queryParams.level);
      }
      this.$store
        .dispatch(GET_ASSESSMENT_CHART, {
          id: this.$route.params.id,
          params: params,
        })
        .then((data) => {
          this.isLoadingChart = false;
          this.chart = data.data;
        })
        .catch((response) => {
          this.isLoadingChart = false;
          this.$bvToast.toast(response.data.message, {
            title: `Gagal Memuat Ujian`,
            variant: "danger",
            solid: true,
          });
        });
    },
    isAlreadyDone(params) {
      return params.is_done;
    },
  },
};
</script>
