import { render, staticRenderFns } from "./SummaryAssesment.vue?vue&type=template&id=89162c0e&scoped=true"
import script from "./SummaryAssesment.vue?vue&type=script&lang=js"
export * from "./SummaryAssesment.vue?vue&type=script&lang=js"
import style0 from "./SummaryAssesment.vue?vue&type=style&index=0&id=89162c0e&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89162c0e",
  null
  
)

export default component.exports