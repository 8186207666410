<template>
  <div>
    <b-row>
      <b-col>
        <b-form-select
          :options="jabatanOptions"
          v-model="jabatan"
          size="sm"
        ></b-form-select>
      </b-col>
      <b-col>
        <b-form-select
          :options="jabatanOptions"
          v-model="jabatan"
          size="sm"
        ></b-form-select>
      </b-col>
    </b-row>
    <div class="rounded my-2">
      <l-map
        class="rounded"
        style="height: 300px"
        :zoom="zoom"
        :center="center"
      >
        <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
        <l-marker :lat-lng="markerLatLng"></l-marker>
      </l-map>
    </div>
    <b-row>
      <b-col>
        <b-container
          class="bg-secondary d-flex align-items-center justify-content-center rounded-md rounded"
          style="height: 100%"
        >
          <div>informasi lainnya</div>
        </b-container>
      </b-col>
      <b-col>
        <RadarChart id="BranchRecapRadar" />
      </b-col>
    </b-row>

    <div class="mt-5">
      <b-table
        :items="[]"
        :fields="fields"
        :busy="isLoading"
        show-empty
        class="table table-head-custom table-vertical-center table-head-bg table-borderless card-table"
        responsive
      >
        <template #empty="scope">
          <h6 class="text-center">Tidak ada Kelas ditemukan</h6>
        </template>
        <template #emptyfiltered="scope">
          <h6 class="text-center">Tidak ada Kelas ditemukan</h6>
        </template>
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>

        <template #cell(index)="row">
          {{ perPage * (currentPage - 1) + (row.index + 1) }}
        </template>

        <template #cell(action)="data">
          <b-dropdown
            variant="light"
            toggle-class="custom-v-dropdown btn btn-light btn-hover-light-primary btn-icon"
            no-caret
            right
            no-flip
          >
            <template v-slot:button-content>
              <i class="ki ki-bold-more-hor"></i>
            </template>
            <div class="navi navi-hover min-w-md-250px">
              <b-dropdown-text tag="div" class="navi-item">
                <router-link
                  :to="`/kelas/${data.item._id}/edit`"
                  class="navi-link"
                >
                  <span class="navi-icon">
                    <i class="flaticon2-pen"></i>
                  </span>
                  <span class="navi-text">Edit Branch</span>
                </router-link>
              </b-dropdown-text>
              <b-dropdown-text tag="div" class="navi-item">
                <span
                  class="navi-link cursor-pointer"
                  @click="deleteItem(data.item)"
                >
                  <span class="navi-icon">
                    <i class="flaticon2-trash"></i>
                  </span>
                  <span class="navi-text">Hapus Branch</span>
                </span>
              </b-dropdown-text>
            </div>
            <!--end::Navigation-->
          </b-dropdown>
          &nbsp;
          <router-link
            :to="`/branch/${data.item._id}/assign/student`"
            href="#"
            class="btn btn-danger font-weight-bolder font-size-sm"
          >
            <i class="flaticon2-user"></i>
            Pegawai</router-link
          >
          &nbsp;
          <!-- <router-link :to="`/kelas/${data.item._id}/assign/teacher`" href="#"
                                    class="btn btn-info font-weight-bolder font-size-sm">
                                    <i class="flaticon2-user"></i>
                                    Guru</router-link> -->
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import L from "leaflet";
import { LMap, LTileLayer, LMarker } from "vue2-leaflet";
import RadarChart from "../chart/RadarChart.vue";

export default {
  name: "RegionRecap",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    RadarChart,
  },
  data() {
    return {
      isLoading: false,
      jabatan: null,
      jabatanOptions: [
        {
          text: "Pilih Jabatan",
          selected: true,
          value: null,
          disabled: true,
        },
      ],
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 5,
      center: [-4.663577, 116.536213],
      markerLatLng: [-4.663577, 116.536213],
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      pageOptions: [10, 20, 50, { value: 100, text: "More" }],
      filter: null,
      fields: [
        {
          key: "index",
          label: "No",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "class_code",
          label: "Kode Branch",
          sortable: true,
        },
        {
          key: "class_name",
          label: "Nama Branch",
          sortable: true,
        },
        {
          key: "subject",
          label: "Subject",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
          sortable: false,
        },
      ],
    };
  },
};
</script>
