<template>
  <div>
    <b-row class="justify-content-between mb-4 align-items-start">
      <b-col cols="auto">
        <a
          @click="$router.back()"
          href="#"
          class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg d-flex flex-row align-items-center"
        >
          <i class="mdi mdi-chevron-left"></i>
          Kembali
        </a>
      </b-col>
    </b-row>
    <b-row>
      <b-col col lg="4" sm="12" class="mb-5">
        <div class="card card-custom">
          <div class="card-header border-0 py-5">
            <h3 class="card-title align-items-start">
              <span class="card-label font-weight-bolder text-muted">
                status
              </span>
              <small>
                <span
                  class="badge bg-light-secondary text-secondary ml-5"
                  v-if="!assessment.is_published"
                  >Ujian Belum Dibuka</span
                >
                <span
                  class="badge bg-light-warning text-warning ml-5"
                  v-else-if="
                    assessment.is_published &&
                    new Date() >= new Date(assessment.opening_time) &&
                    new Date() <= new Date(assessment.closing_time)
                  "
                  >Ujian Sedang Berlangsung
                </span>
                <span class="badge bg-light-success text-success ml-5" v-else>
                  Ujian Berakhir
                </span>
              </small>
            </h3>
          </div>
          <div class="card-body pt-4">
            <div class="mb-5">
              <span class="text-muted">Nama Assessment</span><br />
              <span class="text-dark">{{ assessment.name }}</span>
            </div>
            <div class="mb-5">
              <span class="text-muted">Tipe Assessment</span><br />
              <span class="text-dark">{{ assessment.exam_type }}</span>
            </div>
            <div>
              <span class="text-muted">Tanggal Ujian</span><br />
              <span class="text-dark"
                >{{ assessment.opening_time | moment("LL HH:mm") }} -
                {{ assessment.closing_time | moment("LL HH:mm") }}</span
              >
            </div>
            <div class="mt-5">
              <span class="text-muted">Deskripsi</span><br />
              <span class="text-dark" v-html="assessment.description"></span>
            </div>
          </div>
        </div>
        <div class="mt-3">
          <div class="row">
            <div class="col">
              <div class="bg-danger py-2 px-5 rounded text-center">
                <p class="font-size-sm text-white">Tidak Mengerjakan</p>
                <p class="font-size-h2 text-white">{{ data.not_done }}</p>
              </div>
            </div>
            <div class="col">
              <div class="bg-success py-2 px-5 rounded text-center">
                <p class="font-size-sm text-white">Mengerjakan</p>
                <p class="font-size-h2 text-white">{{ data.is_done }}</p>
              </div>
            </div>
          </div>
        </div>
      </b-col>
      <b-col col lg="8" sm="12">
        <DropdownLevel @selected="getHakAkses" styles="mb-3 w-100" />
        <NationalRecap ref="recapComponents" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import NationalRecap from "./tab/NationalRecap.vue";
import RegionRecap from "./tab/RegionRecap.vue";
import BranchRecap from "./tab/BranchRecap.vue";

import DropdownLevel from "@/view/pages/summary/components/DropdownLevel.vue";

import { GET_ONE_UJIAN } from "@/core/services/store/ujian.module";
import { mapGetters, mapState } from "vuex";
export default {
  name: "Summary",
  components: {
    NationalRecap,
    DropdownLevel,
  },
  data() {
    return {
      isLoading: false,
      assessment: {},
    };
  },
  beforeMount() {
    this.getData();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Value Assessment Summary" },
    ]);
  },
  computed: {
    ...mapState({
      data: (state) => {
        let data = state.ujian.listAssesment;
        let is_done = 0;
        let not_done = 0;
        if (data) {
          data = data.data.forEach((element) => {
            if (element.is_done) is_done++;
            else not_done++;
          });
        }
        return {
          is_done,
          not_done,
        };
      },
    }),
  },
  methods: {
    getData() {
      this.isLoading = true;
      this.$store
        .dispatch(GET_ONE_UJIAN, this.$route.params.id)
        .then((data) => {
          this.isLoading = false;
          this.assessment = data.data;
        })
        .catch((response) => {
          this.isLoading = false;
          this.$bvToast.toast("Pemberitahuan", {
            title: `Gagal Memuat Rekap`,
            variant: "danger",
            solid: true,
          });
        });
    },
    getLevel() {},
    getHakAkses(item) {
      this.$refs.recapComponents.getData({
        level: item,
      });
    },
  },
};
</script>
<style lang="css" scoped>
.nav-tabs .nav-link.active {
  border-bottom: 2px solid #007bff !important;
  /* Add a bottom border */
  /* OR */
  text-decoration: underline !important;
  /* Add underline text decoration */
}
</style>
